<template>
  <div id="file-viewer-wrapper">
    <FileViewer
      :file="file"
      :type="type"
      @back="$emit('back')"
      @next="$emit('next')"
      @previous="$emit('previous')"
    >
      <template #header-left>
        <BaseActionButton
          v-tooltip.top="'files'"
          is-flat
          icon="eva-menu-2"
          class="q-ml-sm"
          @click="$emit('show-files')"
        />
      </template>

      <template #file-name>
        {{ file.name }}
      </template>

      <template #header-right>
        <slot name="header-right"></slot>

        <BaseActionButton
          v-if="checkViewer"
          v-tooltip.top="'Free Hand'"
          is-flat
          icon="mdi-text-recognition"
          class="q-mr-sm"
          @click="$emit('free-hand', file)"
        />

        <BaseActionButton
          v-tooltip:red.top="'delete'"
          is-flat
          icon="eva-trash-2-outline"
          @click="$emit('delete', file)"
        />

        <BaseSeparator is-vertical has-inset class="q-mx-md" />
      </template>
    </FileViewer>
  </div>
</template>

<script>
import FileViewer from "@/components/common/FileViewer.vue";

export default {
  name: "FileViewerWrapper",

  components: { FileViewer },

  props: {
    file: {
      type: Object,
      required: true,
    },

    type: {
      type: Number,
      required: true,
    },
  },

  computed: {
    checkViewer() {
      let origin = location.origin;
      if (
        origin === "http://52.172.32.88" ||
        origin === "http://localhost:8080" ||
        origin === "https://trial.ezofis.com"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-viewer-wrapper {
  // min-height: 100vh;
  background-color: var(--component-bg-color);
}
</style>
