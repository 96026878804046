<template>
  <Confirm
    :value="value"
    icon="mdi-robot-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Auto Process {{ chunk }}?</template>

    <template #default>
      <div class="q-py-md autoProcess">
        Are you sure you want to auto process the following {{ chunk }}? You can
        view the {{ chunk }} under the Index tab.

        <SingleChoiceField
          v-model="indexingOptionValue"
          :options-per-line="3"
          :options="indexingOption"
          :is-readonly="ocrValue !== 'ANY'"
          class="q-mt-md"
        />

        <div v-if="alertMsg" class="text-red q-mt-lg">
          {{ alertMsg }}
        </div>

        <CheckboxField
          v-if="indexingOptionValue === 'STANDARD'"
          v-model="pattern"
          label="Pattern Recognition"
          class="q-mt-lg"
        />

        <div
          v-if="
            indexingOptionValue === 'ADVANCED' || indexingOptionValue === ''
          "
          class="text-secondary q-mt-lg"
        >
          Credit Balance: {{ creditBalance }}
        </div>

        <template v-if="creditBalance !== 0">
          <template v-if="files.length === 1">
            <SelectField
              v-model="selectedPages"
              label="Select pages for Advance Indexing"
              is-mandatory
              :is-clearable="false"
              :options="pages"
              :is-readonly="indexingOptionValue === 'STANDARD'"
              class="q-mt-lg"
            />
          </template>

          <template v-else-if="files.length > 1">
            <SelectField
              v-model="selectedPages"
              label="Select pages for Advance Indexing"
              is-mandatory
              :is-clearable="false"
              :options="secondaryPage"
              :is-readonly="indexingOptionValue === 'STANDARD'"
              class="q-mt-lg"
            />
          </template>
        </template>

        <TextField
          v-if="selectedPages === 'CUSTOM'"
          v-model="pageCount"
          label="Pages"
          is-mandatory
          class="q-mt-lg"
          placeholder="enter page number like 2,3,4  or 2-5"
          @input="convertValue"
        />

        <div v-if="msg" class="text-red q-mt-lg">
          {{ msg }}
        </div>

        <ol class="q-mt-lg">
          <li v-for="file in files" :key="file.id" class="font-semibold">
            {{ file.name }}
          </li>
        </ol>

        <div
          v-if="
            indexingOptionValue === 'ADVANCED' &&
            creditBalance < pagesArray.length
          "
          class="text-red q-mt-lg"
        >
          Plese contact repository owner or administrator to Increase credit
          value
        </div>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton
        label="yes"
        color="orange"
        :disabled="
          indexingOptionValue === 'ADVANCED' &&
          creditBalance < pagesArray.length
        "
        @click="handleYes"
      />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

import { repository } from "@/api/factory.js";

export default {
  name: "ConfirmAutoProcessFiles",

  components: {
    Confirm,
    SingleChoiceField,
    SelectField,
    TextField,
    CheckboxField,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    files: {
      type: Array,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    ocrPages: {
      type: Array,
      default: () => [],
    },

    ocrType: {
      type: String,
      default: "",
    },

    ocrPattern: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      indexingOption: [
        {
          id: this.$nano.id(),
          label: "Standard OCR",
          value: "STANDARD",
        },
        {
          id: this.$nano.id(),
          label: "Advanced OCR",
          value: "ADVANCED",
        },
      ],
      indexingOptionValue: "",
      selectedPages: "FIRST",
      creditBalance: 0,
      pages: [
        {
          id: 1,
          label: "First Page",
          value: "FIRST",
        },
        {
          id: 2,
          label: "All Pages",
          value: "ALL",
        },
        {
          id: 3,
          label: "Custom Pages",
          value: "CUSTOM",
        },
      ],
      secondaryPage: [
        {
          id: 1,
          label: "First Page",
          value: "FIRST",
        },
        {
          id: 2,
          label: "All Pages",
          value: "ALL",
        },
      ],
      pageCount: "",
      pagesArray: [],
      pageLimit: false,
      creditLimit: false,
      msg: "",
      alertMsg: "",
      pattern: false,
      ocrValue: "",
    };
  },

  computed: {
    chunk() {
      if (!this.files.length) {
        return "";
      }

      return this.files.length === 1 ? "file" : "files";
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.getRepository();
          this.getOcrCredit();
        }
      },
    },
    selectedPages: {
      immediate: true,
      handler() {
        this.updateValue();
      },
    },
  },

  methods: {
    handleInput(value) {
      this.reset();
      this.$emit("input", value);
    },

    handleNo() {
      this.reset();
      this.$emit("input", false);
    },

    updateValue() {
      if (this.selectedPages === "FIRST") {
        this.pagesArray = [1];
      } else if (this.selectedPages === "ALL") {
        this.pagesArray = [];
      }
    },

    convertValue() {
      this.pagesArray = [];
      this.pagesArray = this.parsePages(
        this.pageCount,
        this.files[0].totalPages
      );
      if (this.pagesArray.length) {
        this.checkLimit(this.pagesArray);
      }
      this.msg = this.alertText();
    },

    parsePages(pagesString, totalPages) {
      const pageArray = [];

      const parts = pagesString.split(",");

      for (const part of parts) {
        if (part.includes("-")) {
          const [start, end] = part.split("-").map(Number);

          if (!isNaN(start) && !isNaN(end)) {
            if (end <= totalPages) {
              this.pageLimit = false;
            } else {
              this.pageLimit = true;
            }

            for (let i = start; i <= end; i++) {
              pageArray.push(i);
            }
          } else this.pageLimit = false;
        } else {
          const number = parseInt(part);

          if (!isNaN(number)) {
            pageArray.push(number);
            if (number <= totalPages) {
              this.pageLimit = false;
            } else {
              this.pageLimit = true;
            }
          } else this.pageLimit = false;
        }
      }

      return pageArray;
    },

    checkLimit(pages) {
      let lenght = 0;
      lenght = pages.length;

      if (lenght <= this.creditBalance) {
        this.creditLimit = false;
      } else this.creditLimit = true;
    },

    alertText() {
      let text = "";
      if (this.pageLimit && this.creditLimit) {
        text = `Provide valid page number within total pages - ${this.files[0].totalPages} and page count within credit balance - ${this.creditBalance}`;
      } else if (this.pageLimit) {
        text = `Total pages - ${this.files[0].totalPages}, Provide valid page number within total pages`;
      } else if (this.creditLimit) {
        text = `Provide page count within credit balance - ${this.creditBalance}`;
      } else if (this.pageCount) {
        text = "";
      }

      return text;
    },

    handleYes() {
      if (this.indexingOptionValue !== "") {
        this.alertMsg = "";
        const largestValue = Math.max(...this.pagesArray);
        if (largestValue <= this.files[0].totalPages) {
          this.$emit("input", false);
          this.$emit("update:ocrPages", this.pagesArray);
          this.$emit("update:ocrType", this.indexingOptionValue);
          this.$emit("update:ocrPattern", this.pattern);
          this.$emit("update", "AUTO_PROCESSING");
          this.reset();
        } else
          this.alertMsg = `Provide page number within totalpages - ${this.files[0].totalPages}`;
      } else this.alertMsg = "Select any OCR method to process file";
    },

    reset() {
      this.indexingOptionValue = "";
      this.selectedPages = "FIRST";
      this.creditBalance = 0;
      this.pageCount = "";
      this.pagesArray = [];
      this.pageLimit = false;
      this.creditLimit = false;
      this.msg = "";
      this.alertMsg = "";
    },

    // api //

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.ocrValue = payload.indexingType;

      if (payload.indexingType === "ANY") {
        if (payload.fieldsType === "STATIC") {
          this.indexingOptionValue = "STANDARD";
          this.pattern = true;
        } else {
          this.indexingOptionValue = "ADVANCED";
        }
      } else if (payload.indexingType === "ADVANCED") {
        this.indexingOptionValue = "ADVANCED";
      } else if (payload.indexingType === "STANDARD") {
        this.indexingOptionValue = "STANDARD";
        this.pattern = true;
      }
    },

    async getOcrCredit() {
      const { error, payload } = await repository.getOcrCredit(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.creditBalance = payload;
    },
  },
};
</script>

<style lang="scss">
.autoProcess #single-choice-field .options-wrapper {
  border: none !important;
}
</style>
