<template>
  <Confirm
    :value="value"
    icon="mdi-text-box-plus-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Merge {{ chunk }}?</template>

    <template #default>
      <div class="q-py-md">
        Are you sure you want to merge the following
        <span class="file-count"> {{ files.length }} {{ chunk }} </span>?

        <ol class="q-my-lg">
          <li v-for="file in files" :key="file.id" class="font-semibold">
            {{ file.name }}
          </li>
        </ol>

        <div class="note">
          Note: The {{ chunk }} will be merged and saved as PDF.
        </div>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmMergeFiles",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    files: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chunk() {
      if (!this.files.length) {
        return "";
      }

      return this.files.length === 1 ? "file" : "files";
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("merge");
    },
  },
};
</script>

<style lang="scss" scoped>
li:not(:last-child) {
  margin-bottom: 6px;
}

.file-count {
  font-weight: bold;
  color: var(--body-text-color);
}

.note {
  @extend .text-sm;
  color: var(--red);
}
</style>
