<template>
  <div id="uploader">
    <Portal to="upload-index-action-bar">
      <div class="row">
        <ExpandableSearch v-if="false" class="q-mr-sm" @search="searchRow" />

        <FilterItems
          v-if="false"
          item="file"
          is-bordered
          :columns="columns"
          :filter-by.sync="filterBy"
          class="q-mr-sm"
          @update="getUploadedFiles"
        />

        <BaseActionButton
          v-tooltip.top="'refresh'"
          is-flat
          icon="eva-refresh"
          class="q-mr-sm"
          @click="getUploadedFiles"
        />
      </div>
    </Portal>

    <!-- content -->

    <ContentWrapper>
      <template #main>
        <!-- file viewer -->

        <FileViewerWrapper
          v-if="isFileViewerVisible"
          :file="selectedFiles[0]"
          :type="1"
          @back="hideFileViewer"
          @show-files="showFilesSheet"
          @delete="showDeleteConfirmation"
          @next="showNextFile"
          @previous="showPreviousFile"
        >
          <template #header-right>
            <!-- <BaseActionButton
              v-show="showSplitBtn"
              v-tooltip.top="'split'"
              is-flat
              icon="mdi-text-box-minus-outline"
              class="q-mr-sm"
              @click="showSplitConfirmation(selectedFiles[0])"
            /> -->

            <BaseActionButton
              v-show="showAutoProcessBtn"
              v-tooltip.top="'move to autoprocess'"
              is-flat
              icon="mdi-robot-outline"
              class="q-mr-sm"
              @click="showAutoProcessConfirmation(selectedFiles[0])"
            />

            <BaseActionButton
              v-show="showManualIndexBtn"
              v-tooltip.top="'move to manual index'"
              is-flat
              icon="mdi-label-multiple-outline"
              class="q-mr-sm"
              @click="showManualIndexConfirmation(selectedFiles[0])"
            />
          </template>
        </FileViewerWrapper>

        <!-- ... -->

        <!-- files -->

        <BaseScrollbar
          v-else
          height="calc(100vh - 219px)"
          class="q-px-md q-mt-md"
        >
          <BaseLoadingBarSheet
            v-if="loadingBarContent"
            class="loading-content"
          />

          <UploadingFileList :files="filesToUpload" class="q-mb-md" />

          <UploadedFileList
            :files="uploadedFiles"
            :selected-files="selectedFiles"
            :indexing-type="indexingType"
            @select="selectFile"
            @view="showFileViewer"
            @split="showSplitConfirmation"
            @merge="showMergeConfirmation"
            @delete="showDeleteConfirmation"
            @manual-index="showManualIndexConfirmation"
            @auto-process="showAutoProcessConfirmation"
            @selectedFile="selectedOneFile"
            @selectAll="selectAllFile"
          />
        </BaseScrollbar>

        <!-- ... -->

        <!-- pagination -->

        <Pagination
          v-if="!isFileViewerVisible"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          class="q-ma-md"
        />

        <!-- ... -->
      </template>

      <template #aside>
        <BaseScrollbar height="calc(100vh - 124px)">
          <Dropzone
            :workspace-id="workspaceId"
            :repository-id="repositoryId"
            @upload="queueFilesForUpload"
          />
        </BaseScrollbar>
      </template>
    </ContentWrapper>

    <!-- ... -->

    <!-- file sheet -->

    <FileSheet
      v-model="isFilesSheetVisible"
      :file-list="fileList"
      :selected-files="selectedFiles"
      @select="singleSelectFile"
    />

    <!-- ... -->

    <!-- delete confirmation -->

    <ConfirmDeleteFiles
      v-model="isDeleteConfirmationVisible"
      :files="selectedFiles"
      @delete="deleteFiles"
    />

    <!-- ... -->

    <!-- auto process confirmation -->

    <ConfirmAutoProcessFiles
      v-model="isAutoProcessConfirmationVisible"
      :repository-id="repositoryId"
      :files="selectedFiles"
      :ocr-pages.sync="ocrPages"
      :ocr-type.sync="ocrType"
      :ocr-pattern.sync="ocrPattern"
      @update="setStatus"
    />

    <!-- ... -->

    <!-- manual index confirmation -->

    <ConfirmManualIndexFiles
      v-model="isManualIndexConfirmationVisible"
      :files="selectedFiles"
      @update="setStatus"
    />

    <!-- ... -->

    <!-- merge confirmation -->

    <ConfirmMergeFiles
      v-model="isMergeConfirmationVisible"
      :files="selectedFiles"
      @merge="mergeFiles"
    />

    <!-- ... -->

    <!-- split confirmation -->

    <ConfirmSplitFiles
      v-model="isSplitConfirmationVisible"
      :files="selectedFiles"
      @split="splitFiles"
    />

    <!-- ... -->
  </div>
</template>

<script>
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
import ContentWrapper from "@/views/upload-and-index/components/ContentWrapper.vue";
import UploadingFileList from "./components/UploadingFileList";
import UploadedFileList from "./components/UploadedFileList";
import Pagination from "@/components/common/display/Pagination.vue";
import Dropzone from "./components/Dropzone.vue";
import FileViewerWrapper from "@/views/upload-and-index/components/FileViewerWrapper.vue";
import ConfirmDeleteFiles from "@/views/upload-and-index/components/ConfirmDeleteFiles.vue";
import ConfirmAutoProcessFiles from "@/views/upload-and-index/components/ConfirmAutoProcessFiles.vue";
import ConfirmManualIndexFiles from "@/views/upload-and-index/components/ConfirmManualIndexFiles.vue";
import ConfirmMergeFiles from "./components/ConfirmMergeFiles.vue";
import ConfirmSplitFiles from "./components/ConfirmSplitFiles.vue";
import FileSheet from "@/views/upload-and-index/components/FileSheet.vue";
import axios from "axios";
import { uploadAndIndex, repository } from "@/api/factory.js";
import { startCase, capitalize } from "lodash-es";
import { fileSupport } from "@/helpers/file-format.js";

export default {
  name: "Uploader",

  components: {
    Portal,
    ExpandableSearch,
    FilterItems,
    ContentWrapper,
    UploadingFileList,
    UploadedFileList,
    Pagination,
    Dropzone,
    FileViewerWrapper,
    ConfirmDeleteFiles,
    ConfirmAutoProcessFiles,
    ConfirmManualIndexFiles,
    ConfirmMergeFiles,
    ConfirmSplitFiles,
    FileSheet,
  },

  props: {
    workspaceId: {
      type: Number,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    uploadFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "repository",
          label: "Repository",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "uploadedFrom",
          label: "Uploaded From",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "lastModifiedBy",
          label: "Last Modified By",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "lastModifiedAt",
          label: "Last Modified At",
          dataType: DataType.DATE_TIME,
        },
      ],
      filterBy: [
        {
          filters: [
            {
              criteria: "status",
              condition: "IS_EQUALS_TO",
              value: "Uploaded",
            },
          ],
        },
      ],
      filesToUpload: [],
      uploadedFiles: [],
      uploadedFilesData: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      selectedFiles: [],
      ocrPages: [1], //[]
      ocrType: "",
      isFileViewerVisible: false,
      isFilesSheetVisible: false,
      isDeleteConfirmationVisible: false,
      isAutoProcessConfirmationVisible: false,
      isManualIndexConfirmationVisible: false,
      isMergeConfirmationVisible: false,
      isSplitConfirmationVisible: false,
      ocrPattern: false,
      indexingType: "",
      loadingBarContent: false,
    };
  },

  computed: {
    fileList() {
      return [
        {
          status: "uploaded",
          files: this.uploadedFiles,
        },
      ];
    },

    getFileTypes() {
      const groupedFileTypes = this.selectedFiles.reduce((acc, cur) => {
        const fileType = this.getFileType(cur.name);
        acc[fileType] = acc[fileType] + 1 || 1;
        return acc;
      }, {});

      return Object.keys(groupedFileTypes);
    },

    showAutoProcessBtn() {
      if (this.selectedFiles.length === 0 || this.indexingType === "MANUAL") {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        ["pdf", "tif", "tiff", "png", "jpeg", "jpg"].includes(type)
      );
    },

    showManualIndexBtn() {
      let flag = true;
      if (this.selectedFiles.length) {
        this.selectedFiles.forEach((file) => {
          if (file.repository.fieldsType === "DYNAMIC") {
            flag = false;
          }
        });
      }
      return flag;
    },
  },

  watch: {
    // filesToUpload() {
    //   if (!this.filesToUpload.length) {
    //     return;
    //   }
    //   this.filesToUpload.forEach((file, index) => {
    //     if (file.uploadedPercentage === 0) {
    //       this.uploadFile(file, index);
    //     }
    //   });
    // },
    repositoryId: {
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.getRepository();
        }
      },
    },

    uploadFiles: {
      immediate: true,
      deep: true,
      handler() {
        if (this.uploadFiles.length) {
          this.queueFilesForUpload(this.uploadFiles);
          this.$emit("update:uploadFiles", []);
        }
      },
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.currentPage, vm.itemsPerPage],
      () => this.getUploadedFiles(),
      { immediate: true, deep: true }
    );
  },

  methods: {
    queueFilesForUpload(files) {
      this.filesToUpload.push(...files);
      this.filesToUpload.forEach(async (file, index) => {
        if (file.uploadedPercentage === 0) {
          await this.uploadFile(file, index);
        }
      });
    },

    selectFile(file) {
      const fileIdx = this.selectedFiles.findIndex(
        (_file) => _file.id === file.id
      );
      const isFileAlreadySelected = fileIdx > -1;

      isFileAlreadySelected
        ? this.selectedFiles.splice(fileIdx, 1)
        : this.selectedFiles.push(file);
    },

    singleSelectFile(file) {
      this.selectedFiles = [file];
    },

    showFileViewer(file) {
      if (file.uploadedFrom === "Web") {
        this.selectedFiles = [file];
        this.isFileViewerVisible = true;
      }
    },

    hideFileViewer() {
      this.isFileViewerVisible = false;
      this.selectedFiles = [];
    },

    showFilesSheet() {
      this.isFilesSheetVisible = true;
    },

    showDeleteConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isDeleteConfirmationVisible = true;
    },

    showAutoProcessConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      //this.isAutoProcessConfirmationVisible = true;
      this.setStatus("AUTO_PROCESSING");
    },

    showManualIndexConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.setStatus("NOT_INDEXED");
      //this.isManualIndexConfirmationVisible = true;
    },

    showMergeConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isMergeConfirmationVisible = true;
    },

    showSplitConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isSplitConfirmationVisible = true;
    },

    showPreviousFile() {
      const idx = this.uploadedFiles.findIndex(
        (file) => file.id === this.selectedFiles[0].id
      );

      if (idx - 1 >= 0) {
        this.selectedFiles = [this.uploadedFiles[idx - 1]];
      }
    },

    showNextFile() {
      const idx = this.uploadedFiles.findIndex(
        (file) => file.id === this.selectedFiles[0].id
      );

      if (idx + 1 < this.uploadedFiles.length) {
        this.selectedFiles = [this.uploadedFiles[idx + 1]];
      }
    },

    refreshFileList() {
      this.filesToUpload = this.filesToUpload.filter(
        (_file) => _file.uploadedPercentage !== 100
      );

      this.getUploadedFiles();
    },

    showSplitBtn() {
      if (this.selectedFiles.length === 0) {
        return false;
      }

      const fileType = this.selectedFiles[0].name
        .split(".")
        .reverse()[0]
        .slice(0, 4)
        .toLowerCase();
      return ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(
        fileType
      );
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0].slice(0, 4).toLowerCase();
    },

    searchRow(search) {
      if (search) {
        this.uploadedFiles = this.uploadedFilesData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.uploadedFiles = this.uploadedFilesData;
      }
    },

    /* api Functions */

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.fieldsType === "STATIC") {
        this.ocrType = "STANDARD";
      } else {
        this.ocrType = "ADVANCED";
      }

      this.indexingType = payload.indexingType;
    },

    async getUploadedFiles() {
      //this.totalItems = 0;
      //this.$store.commit("showLoadingBar");
      this.loadingBarContent = true;

      this.uploadedFiles = [];
      this.selectedFiles = [];
      const { error, payload } = await uploadAndIndex.getUploadedFiles({
        repositoryId: this.repositoryId,
        mode: "BROWSE",
        sortBy: {
          criteria: "createdAt",
          order: "DESC",
        },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "status",
                condition: "IS_EQUALS_TO",
                value: "UPLOADED",
              },
            ],
          },
        ],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBar");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      this.totalItems = meta.totalItems;

      if (!data.length) {
        return;
      }

      this.uploadedFiles = data[0].value.map((file) => ({
        id: file.id,
        name: file.name,
        status: file.status,
        isSplitted: false,
        isMerged: false,
        uploadedFrom: startCase(capitalize(file.uploadedFrom)),
        repository: file.repository,
        createdAt: file.createdAt,
        createdBy: file.createdBy,
        totalPages: file.totalPage,
      }));

      this.uploadedFilesData = this.uploadedFiles;
    },

    async uploadFile(file, index) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("repositoryId", this.repositoryId);

      let filename = file.name;
      const ext = filename.split(".").pop();

      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            const uploadedPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            file.uploadedPercentage = uploadedPercentage;
          },
        });

        const { status } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        this.filesToUpload.splice(index, 1);
        this.refreshFileList();
      } catch (error) {
        console.error(error);
        this.$alert.error(`error uploading file - ${file.name}`);
      }

      // remove file
    },

    async setStatus(status) {
      //this.$store.commit("showLoadingBar");
      let ocrTool = "";
      if (status === "AUTO_PROCESSING" && this.ocrType) {
        if (this.ocrType === "STANDARD") {
          ocrTool = "LEADTOOLS";
        } else {
          ocrTool = "SPIRE";
        }
      }

      let ocrTypeIndexing = this.ocrType;

      let origin = location.origin;
      if (origin === "https://trial.ezofis.com") {
        ocrTypeIndexing = "ADVANCED";
      }

      const payload = this.selectedFiles.map((file) => ({
        id: file.id,
        status,
        OCRPages: this.ocrPages,
        OCRType: status === "AUTO_PROCESSING" ? ocrTypeIndexing : "MANUAL",
        OCRTool: ocrTool,
        OCRPattern: this.ocrPattern,
      }));
      //console.log(payload);
      const { error } = await uploadAndIndex.uploadSetStatus(payload);

      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // this.$alert.success(
      //   `${this.selectedFiles.length} ${
      //     this.selectedFiles.length > 1 ? "files" : "file"
      //   } moved to index page`
      // );
      this.$emit("move");
      //this.getUploadedFiles();
    },

    async deleteFiles() {
      //this.$store.commit("showLoadingBar");

      const payload = this.selectedFiles.map((file) => file.id);
      const { error } = await uploadAndIndex.deleteUploadedFiles(payload);

      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // this.$alert.success(
      //   `${this.selectedFiles.length} ${
      //     this.selectedFiles.length > 1 ? "files" : "file"
      //   } deleted successfully`
      // );

      this.getUploadedFiles();
    },

    mergeFiles() {},

    splitFiles() {},

    selectedOneFile(file) {
      const fileIdx = this.selectedFiles.findIndex(
        (_file) => _file.id === file.id
      );

      const isFileAlreadySelected = fileIdx > -1;

      this.selectedFiles = [];

      if (!isFileAlreadySelected) {
        this.selectedFiles.push(file);
      }
    },

    selectAllFile(file, type) {
      if (file) {
        if (type === "UPLOADED") {
          this.selectedFiles = this.uploadedFiles;
        }
      } else {
        this.selectedFiles = [];
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute !important;
  z-index: 9999;
}
</style>
