<template>
  <div id="not-indexed-file-list">
    <FileList
      :has-files="hasFiles"
      :has-selected-files="hasSelectedFiles"
      :number-of-files="files.length"
      class="q-mb-md"
      :show-auto-load="showAutoLoad"
      @getFiles="$emit('getFiles', 'INDEXING')"
    >
      <!-- label -->

      <template #label>
        <span v-if="label" class="label">{{ label }}</span>
        <span v-else>Indexing</span>
      </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions-maximized>
        <template v-if="hasSelectedFiles">
          <BaseActionButton
            v-show="showAutoProcessBtn"
            v-tooltip.top="'move to autoprocess'"
            is-flat
            icon="mdi-robot-outline"
            class="q-mr-sm"
            @click.stop="$emit('process')"
          />

          <BaseActionButton
            v-tooltip:red.top="'Delete'"
            is-flat
            color="red"
            icon="eva-trash-2-outline"
            class="q-mr-md"
            @click.stop="$emit('delete')"
          />
        </template>
        <div v-if="files.length" class="row">
          <div class="col">
            <!-- <div class="label">Select All</div> -->
          </div>

          <BaseIcon
            v-tooltip.top="'select all'"
            :name="
              isSelectAll
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :color="isSelectAll ? 'secondary' : 'grey'"
            class="q-mx-sm cursor-pointer col-auto"
            @click.stop="selectAllFile"
          />
        </div>
      </template>

      <!-- ... -->

      <!-- files -->

      <template #default>
        <FileListItem
          v-for="file in files"
          :key="file.id"
          :file="file"
          :is-selected="isFileSelected(file)"
          @view="$emit('view', file)"
          @selectFile="fileSelect(file)"
        >
          <!-- @mouseleave="fileId = 0" -->
          <!-- status -->

          <template #status>
            <BaseIcon name="mdi-circle-small" />
            <div :class="file.status">{{ file.status }}</div>
          </template>

          <!-- ... -->

          <!-- actions -->

          <BaseIcon
            :name="
              isFileSelected(file)
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :is-inactive="!isFileSelected(file)"
            color="secondary"
            class="q-mr-sm"
            @click.stop="$emit('select', file)"
          />

          <BaseActionButton
            is-flat
            no-border
            icon="eva-more-vertical"
            @click.stop=""
          >
            <q-menu
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
              content-class="q-py-sm"
            >
              <ListItem
                v-for="action in getActions(file)"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="$emit(action.value, file)"
              />
            </q-menu>
          </BaseActionButton>

          <!-- ... -->
        </FileListItem>
      </template>

      <!-- ... -->
    </FileList>
  </div>
</template>

<script>
import FileList from "@/views/upload-and-index/components/FileList.vue";
import FileListItem from "@/views/upload-and-index/components/FileListItem.vue";
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "IndexingFileList",

  components: { FileList, FileListItem, ListItem },

  props: {
    files: {
      type: Array,
      required: true,
    },

    selectedFiles: {
      type: Array,
      required: true,
    },

    indexingType: {
      type: String,
      required: true,
    },

    fieldsType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          id: this.$nano.id(),
          icon: "eva-eye-outline",
          label: "View",
          value: "view",
        },
        {
          id: this.$nano.id(),
          icon: "mdi-robot-outline",
          label: "Auto-process",
          value: "process",
        },
        {
          id: this.$nano.id(),
          icon: "eva-trash-2-outline",
          label: "Delete",
          value: "delete",
        },
      ],
      fileId: 0,
      isSelectAll: false,
      showAutoLoad: true,
    };
  },

  computed: {
    hasFiles() {
      return !!this.files.length;
    },

    notIndexedFiles() {
      return this.selectedFiles.filter(
        (file) => file.status !== "EXPORT" && file.status !== "AUTO_PROCESSING"
      ).length;
    },

    hasSelectedFiles() {
      return !!this.notIndexedFiles;
    },

    label() {
      if (!this.hasSelectedFiles) {
        return "";
      }

      const chunk = this.notIndexedFiles === 1 ? "file" : "files";
      return `${this.notIndexedFiles} ${chunk} selected`;
    },

    getFileTypes() {
      const groupedFileTypes = this.selectedFiles.reduce((acc, cur) => {
        const fileType = this.getFileType(cur.name);
        acc[fileType] = acc[fileType] + 1 || 1;
        return acc;
      }, {});

      return Object.keys(groupedFileTypes);
    },

    showAutoProcessBtn() {
      if (
        this.selectedFiles.length === 0 ||
        this.indexingType === "MANUAL" ||
        this.fieldsType === "STATIC"
      ) {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        ["pdf", "tif", "tiff", "png", "jpeg", "jpg"].includes(type)
      );
    },
  },

  methods: {
    isFileSelected(file) {
      return !!this.selectedFiles.find((_file) => _file.id === file.id);
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0].slice(0, 4).toLowerCase();
    },

    getActions(file) {
      let filterActions = this.actions;
      const fileType = this.getFileType(file.name);
      let autoProcessRequired = [
        "pdf",
        "tif",
        "tiff",
        "png",
        "jpeg",
        "jpg",
      ].includes(fileType);
      if (!autoProcessRequired || this.indexingType === "MANUAL") {
        filterActions = filterActions.filter((row) => row.value !== "process");
      }
      return filterActions;
    },

    fileSelect(file) {
      this.$emit("selectedFile", file);
    },

    selectAllFile() {
      this.isSelectAll = !this.isSelectAll;
      this.$emit("selectAll", this.isSelectAll, "INDEXING");
    },
  },
};
</script>

<style lang="scss" scoped>
#not-indexed-file-list {
  .label {
    color: var(--secondary);
  }

  .NOT_INDEXED {
    color: var(--red);
  }

  .PARTIALLY_INDEXED {
    color: var(--orange);
  }

  .INDEXED {
    color: var(--green);
  }

  .EXTRACTED {
    color: var(--secondary);
  }
}
</style>
