var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Confirm',{attrs:{"value":_vm.value,"icon":"mdi-robot-outline","icon-color":"orange"},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Auto Process "+_vm._s(_vm.chunk)+"?")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"q-py-md autoProcess"},[_vm._v(" Are you sure you want to auto process the following "+_vm._s(_vm.chunk)+"? You can view the "+_vm._s(_vm.chunk)+" under the Index tab. "),_c('SingleChoiceField',{staticClass:"q-mt-md",attrs:{"options-per-line":3,"options":_vm.indexingOption,"is-readonly":_vm.ocrValue !== 'ANY'},model:{value:(_vm.indexingOptionValue),callback:function ($$v) {_vm.indexingOptionValue=$$v},expression:"indexingOptionValue"}}),(_vm.alertMsg)?_c('div',{staticClass:"text-red q-mt-lg"},[_vm._v(" "+_vm._s(_vm.alertMsg)+" ")]):_vm._e(),(_vm.indexingOptionValue === 'STANDARD')?_c('CheckboxField',{staticClass:"q-mt-lg",attrs:{"label":"Pattern Recognition"},model:{value:(_vm.pattern),callback:function ($$v) {_vm.pattern=$$v},expression:"pattern"}}):_vm._e(),(
          _vm.indexingOptionValue === 'ADVANCED' || _vm.indexingOptionValue === ''
        )?_c('div',{staticClass:"text-secondary q-mt-lg"},[_vm._v(" Credit Balance: "+_vm._s(_vm.creditBalance)+" ")]):_vm._e(),(_vm.creditBalance !== 0)?[(_vm.files.length === 1)?[_c('SelectField',{staticClass:"q-mt-lg",attrs:{"label":"Select pages for Advance Indexing","is-mandatory":"","is-clearable":false,"options":_vm.pages,"is-readonly":_vm.indexingOptionValue === 'STANDARD'},model:{value:(_vm.selectedPages),callback:function ($$v) {_vm.selectedPages=$$v},expression:"selectedPages"}})]:(_vm.files.length > 1)?[_c('SelectField',{staticClass:"q-mt-lg",attrs:{"label":"Select pages for Advance Indexing","is-mandatory":"","is-clearable":false,"options":_vm.secondaryPage,"is-readonly":_vm.indexingOptionValue === 'STANDARD'},model:{value:(_vm.selectedPages),callback:function ($$v) {_vm.selectedPages=$$v},expression:"selectedPages"}})]:_vm._e()]:_vm._e(),(_vm.selectedPages === 'CUSTOM')?_c('TextField',{staticClass:"q-mt-lg",attrs:{"label":"Pages","is-mandatory":"","placeholder":"enter page number like 2,3,4  or 2-5"},on:{"input":_vm.convertValue},model:{value:(_vm.pageCount),callback:function ($$v) {_vm.pageCount=$$v},expression:"pageCount"}}):_vm._e(),(_vm.msg)?_c('div',{staticClass:"text-red q-mt-lg"},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e(),_c('ol',{staticClass:"q-mt-lg"},_vm._l((_vm.files),function(file){return _c('li',{key:file.id,staticClass:"font-semibold"},[_vm._v(" "+_vm._s(file.name)+" ")])}),0),(
          _vm.indexingOptionValue === 'ADVANCED' &&
          _vm.creditBalance < _vm.pagesArray.length
        )?_c('div',{staticClass:"text-red q-mt-lg"},[_vm._v(" Plese contact repository owner or administrator to Increase credit value ")]):_vm._e()],2)]},proxy:true},{key:"footer",fn:function(){return [_c('BaseButton',{staticClass:"q-mr-sm",attrs:{"is-flat":"","label":"no","color":"orange"},on:{"click":_vm.handleNo}}),_c('BaseButton',{attrs:{"label":"yes","color":"orange","disabled":_vm.indexingOptionValue === 'ADVANCED' &&
        _vm.creditBalance < _vm.pagesArray.length},on:{"click":_vm.handleYes}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }