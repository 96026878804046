<template>
  <div id="upload-and-index">
    <Header v-model="tab" :is-loading="isLoading" @close="closeUploder" />

    <Uploader
      v-if="tab === 'UPLOAD'"
      :workspace-id="workspaceId"
      :repository-id="repositoryId"
      :upload-files.sync="uploadFiles"
      @move="tab = 'INDEX'"
    />

    <Indexer
      v-if="tab === 'INDEX'"
      :workspace-id="workspaceId"
      :repository-id="repositoryId"
      @upload="queueFilesForUpload"
    />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Uploader from "./views/uploader/Uploader.vue";
import Indexer from "./views/indexer/Indexer.vue";

export default {
  name: "UploadAndIndex",

  components: { Header, Uploader, Indexer },

  props: {
    workspaceId: {
      type: Number,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      tab: "UPLOAD",
      isLoading: false,
      uploadFiles: [],
    };
  },

  methods: {
    closeUploder() {
      this.$router.back();
    },

    queueFilesForUpload(files) {
      this.tab = "UPLOAD";
      this.uploadFiles = files;
    },
  },
};
</script>

<style lang="scss" scoped>
#upload-and-index {
  min-height: 100vh;
  background-color: var(--component-bg-color);
}
</style>
