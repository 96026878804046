import { render, staticRenderFns } from "./UploadedFileList.vue?vue&type=template&id=4020f9e5&scoped=true&"
import script from "./UploadedFileList.vue?vue&type=script&lang=js&"
export * from "./UploadedFileList.vue?vue&type=script&lang=js&"
import style0 from "./UploadedFileList.vue?vue&type=style&index=0&id=4020f9e5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4020f9e5",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu});
