<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>Delete {{ chunk }}?</template>

    <template #default>
      <div class="q-py-md">
        <div>
          Are you sure you want to delete the following {{ chunk }}? You cannot
          undo this action.
        </div>

        <ol class="q-my-lg">
          <li v-for="file in files" :key="file.id" class="font-semibold">
            {{ file.name }}
          </li>
        </ol>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="red"
        @click="handleNo"
      />
      <BaseButton label="yes" color="red" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmDeleteFiles",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    files: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chunk() {
      if (!this.files.length) {
        return "";
      }

      return this.files.length === 1 ? "file" : "files";
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss" scoped></style>
