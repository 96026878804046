<template>
  <Confirm
    :value="value"
    icon="mdi-text-box-minus-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Export Files</template>

    <template #default>
      <div class="q-py-md">
        Are you sure you want to export the following {{ chunk }}?

        <ol class="q-my-lg">
          <li v-for="file in files" :key="file.id" class="font-semibold">
            {{ file.name }}
          </li>
        </ol>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmExportFiles",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    files: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chunk() {
      if (!this.files.length) {
        return "";
      }

      return this.files.length === 1 ? "file" : "files";
    },
  },

  methods: {
    handleInput() {
      this.$emit("input", false);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("export", "EXPORT");
    },
  },
};
</script>

<style lang="scss" scoped></style>
