<template>
  <div id="content-wrapper">
    <div class="file-list">
      <slot name="main"></slot>
    </div>

    <div class="aside">
      <slot name="aside"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentWrapper",
};
</script>

<style lang="scss" scoped>
#content-wrapper {
  min-height: calc(100vh - 125px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }
}
</style>
