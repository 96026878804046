<template>
  <Sheet :value="value" position="left" width="360px" @input="closeSheet">
    <template #title> Files </template>

    <div id="file-sheet">
      <div v-for="group in fileList" :key="group.status">
        <!-- header -->

        <div class="header" @click="toggleContent">
          <div class="label">{{ group.status }}</div>

          <q-space />

          <BaseIcon
            name="mdi-menu-down"
            inherit-color
            class="icon"
            :class="{ 'rotate-180': isContentMaximized }"
          />
        </div>

        <!-- ... -->

        <!-- content -->

        <q-expansion-item :value="isContentMaximized" :header-style="_styles">
          <template v-if="group.files.length">
            <FileListItem
              v-for="file in group.files"
              :key="file.id"
              :file="file"
              mini-mode
              :is-selected="isFileSelected(file)"
              class="q-pb-sm"
              @click="$emit('select', file)"
            />
          </template>

          <div v-else class="no-files">No files found</div>
        </q-expansion-item>

        <!-- ... -->
      </div>
    </div>
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import FileListItem from "./FileListItem.vue";

export default {
  name: "FileSheet",

  components: { Sheet, FileListItem },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    fileList: {
      type: Array,
      required: true,
    },

    selectedFiles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isContentMaximized: true,
    };
  },

  computed: {
    _styles() {
      return {
        minHeight: "0px",
        height: "0px",
        padding: "0px",
        overflow: "hidden",
      };
    },
  },

  methods: {
    closeSheet() {
      this.$emit("input", false);
    },

    toggleContent() {
      this.isContentMaximized = !this.isContentMaximized;
    },

    isFileSelected(file) {
      return !!this.selectedFiles.find((_file) => _file.id === file.id);
    },
  },
};
</script>

<style lang="scss" scoped>
#file-sheet {
  .header {
    display: flex;
    align-items: center;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }

    .label {
      flex: 1;
      color: var(--icon-color);
      font-weight: 500;
      font-size: 13px;
      text-transform: capitalize;
    }

    .icon {
      color: var(--icon-color-inverted);
      transition: all 0.3s ease;
    }
  }

  .no-files {
    color: var(--icon-color);
    text-align: center;
    padding: 0px 16px 16px;
  }
}
</style>
