var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"value":_vm.value,"width":"80vw","has-footer":""},on:{"input":_vm.closeModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.label))]},proxy:true},{key:"default",fn:function(){return [_c('div',{attrs:{"id":"table-field-input"}},[_c('BaseScrollbar',[_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.columns),function(column){return _c('th',{key:column._id},[_vm._v(" "+_vm._s(column.name)+" ")])}),_c('th',{staticClass:"action"},[_c('BaseActionButton',{attrs:{"is-flat":"","color":"secondary","icon":"eva-plus-outline","no-border":""},on:{"click":_vm.addRow}})],1)],2)]),_c('tbody',[_vm._l((_vm.tableModel),function(row,index){return _c('tr',{key:index},[_vm._l((_vm.columns),function(column){return _c('td',{key:column._id},[_c('ValidationObserver',{ref:"form",refInFor:true},[(
                      column.dataType === 'SHORT_TEXT' ||
                      column.dataType === 'BARCODE'
                    )?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextField',{attrs:{"is-mandatory":column.isMandatory,"error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(column.dataType === 'NUMBER')?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{
                      required: column.isMandatory,
                      integer: !column.options.allowDecimals,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('NumberField',{attrs:{"is-mandatory":"","error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(column.dataType === 'DATE')?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateField',{attrs:{"is-mandatory":"","error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(column.dataType === 'TIME')?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TimeField',{attrs:{"is-mandatory":column.isMandatory,"error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(column.dataType === 'DATE_TIME')?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateTimeField',{attrs:{"is-mandatory":column.isMandatory,"error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(
                      column.dataType === 'SINGLE_SELECT' &&
                      ['EXISTING', 'MASTER'].includes(
                        column.options.optionsType
                      )
                    )?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('AsyncSelectField',{attrs:{"is-mandatory":column.isMandatory,"error":errors[0],"new-option":column.options.allowToAddNewOptions},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(
                      column.dataType === 'SINGLE_SELECT' &&
                      column.options.optionsType === 'PREDEFINED'
                    )?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectField',{attrs:{"is-mandatory":column.isMandatory,"error":errors[0],"new-option":column.options.allowToAddNewOptions,"options":_vm.predefinedSelectOptions},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e(),(column.dataType === 'BOOLEAN')?_c('ValidationProvider',{attrs:{"name":column.name,"rules":{ required: column.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SingleChoiceField',{attrs:{"is-mandatory":column.isMandatory,"options":_vm.booleanOptions,"error":errors[0]},model:{value:(row[column.name]),callback:function ($$v) {_vm.$set(row, column.name, $$v)},expression:"row[column.name]"}})]}}],null,true)}):_vm._e()],1)],1)}),_c('td',{staticClass:"action"},[_c('BaseActionButton',{attrs:{"is-flat":"","color":"red","icon":"eva-close-outline","no-border":""},on:{"click":function($event){return _vm.removeRow(index)}}})],1)],2)}),_c('tr',[_vm._l((_vm.columns),function(column){return _c('th',{key:column._id},[(column.options.showColumnSum)?_c('div',{staticClass:"q-ml-sm"},[_vm._v(" "+_vm._s(_vm.getColumnSum(column.name))+" ")]):_vm._e()])}),_c('td',{staticStyle:{"height":"56px"}})],2)],2)])])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('BaseButton',{staticClass:"q-mr-sm",attrs:{"is-flat":"","label":"cancel"},on:{"click":_vm.closeModal}}),_c('BaseButton',{attrs:{"label":"save"},on:{"click":_vm.save}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }