<template>
  <Confirm
    :value="value"
    icon="mdi-text-box-minus-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Split Files</template>

    <template #default>
      <!-- ... -->

      <div class="q-mt-md">
        Are you sure you want to split the following
        <span class="file-count"> {{ files.length }} {{ chunk }} </span>?

        <ol class="q-mb-lg">
          <li v-for="file in files" :key="file.id" class="font-semibold">
            {{ file.name }}
          </li>
        </ol>
      </div>

      <div class="note">
        Note: The {{ chunk }} will be splitted and saved as separate PDFs.
      </div>

      <!-- ... -->

      <BaseSeparator class="q-my-md" />

      <!-- split option -->

      <SelectField
        v-model="splitUsing"
        is-mandatory
        label="split option"
        :is-clearable="false"
        :is-searchable="false"
        :options="splitUsingOptions"
        class="q-mb-md"
      />

      <!-- ... -->

      <!-- ... -->

      <div
        v-if="splitUsing === 'BARCODE'"
        class="advanced-options"
        @click="advancedSettings = !advancedSettings"
      >
        advanced options
      </div>

      <!-- ... -->

      <template v-if="advancedSettings">
        <!-- barcode type -->

        <SelectField
          v-model="barcodeType"
          is-mandatory
          label="barcode type"
          :is-clearable="false"
          :options="barcodeTypes"
          class="q-mb-md"
        />

        <!-- ... -->

        <!-- barcode prefix -->

        <TextField
          v-model="barcodePrefix"
          is-mandatory
          label="barcode prefix"
          class="q-mb-md"
        />

        <!-- ... -->

        <!-- barcode length -->

        <NumberField
          v-model="barcodeLength"
          is-mandatory
          label="barcode length"
          class="q-mb-lg"
        />

        <!-- ... -->

        <!-- recognize barcode -->

        <CheckboxField
          v-model="recognizeBarcode"
          :label="`Recognize all the barcodes from the ${chunk}`"
          class="q-mb-md"
        />

        <!-- ... -->
      </template>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "ConfirmSplitFiles",

  components: { Confirm, SelectField, TextField, NumberField, CheckboxField },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    files: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      splitUsing: "SINGLE_PAGE",
      splitUsingOptions: [
        {
          id: this.$nano.id(),
          label: "Single Page Split",
          value: "SINGLE_PAGE",
        },
        {
          id: this.$nano.id(),
          label: "Blank Page Split",
          value: "BLANK_PAGE",
        },
        {
          id: this.$nano.id(),
          label: "Dark Page Split",
          value: "DARK_PAGE",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "MRZ Code Split",
        //   value: "MRZ",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Barcode Split",
        //   value: "BARCODE",
        // },
      ],
      barcodeType: "",
      barcodeTypes: [
        {
          id: this.$nano.id(),
          label: "Quick Response (QR) Codes",
          value: "Quick Response (QR) Codes",
        },
        {
          id: this.$nano.id(),
          label: "Universal Product Codes (UPC)",
          value: "Universal Product Codes (UPC)",
        },
        {
          id: this.$nano.id(),
          label: "Data Matrix",
          value: "Data Matrix",
        },
      ],
      barcodePrefix: "",
      barcodeLength: "",
      advancedSettings: false,
      recognizeBarcode: false,
    };
  },

  computed: {
    chunk() {
      if (!this.files.length) {
        return "";
      }

      return this.files.length === 1 ? "file" : "files";
    },
  },

  methods: {
    handleInput() {
      this.$emit("input", false);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("split", this.splitUsing);
    },
  },
};
</script>

<style lang="scss" scoped>
li:not(:last-child) {
  margin-bottom: 6px;
}

.file-count {
  font-weight: bold;
  color: var(--body-text-color);
}

.advanced-options {
  @extend .text-sm;
  color: var(--secondary);
  text-decoration: underline;
  margin-bottom: 16px;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
  }
}

.note {
  @extend .text-sm;
  color: var(--red);
  margin-bottom: 16px;
}
</style>
