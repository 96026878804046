<template>
  <div id="table-field">
    <FormFieldLabel :label="field.name" :is-mandatory="field.isMandatory" />

    <!-- edit table -->

    <BaseActionButton
      v-tooltip:secondary.top="'edit table'"
      is-flat
      color="secondary"
      icon="eva-edit-outline"
      no-border
      class="edit-table"
      @click="showTableFieldInput"
    />

    <!-- ... -->

    <!-- preview -->

    <BaseScrollbar>
      <table v-if="value.length">
        <thead>
          <tr>
            <th v-for="column in field.options.columns" :key="column._id">
              {{ column.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in value" :key="index">
            <td v-for="column in field.options.columns" :key="column._id">
              {{ row[column.name] }}
            </td>
          </tr>

          <tr>
            <th v-for="column in field.options.columns" :key="column._id">
              <div v-if="column.options.showColumnSum">
                {{ getColumnSum(column.name) }}
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <!-- ... -->

    <!-- input -->

    <TableFieldInput
      v-model="isTableFieldInputVisible"
      :label="field.name"
      :columns="field.options.columns"
      :fields-model="value"
      @save="handleSave"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TableFieldInput from "./TableFieldInput.vue";

export default {
  name: "TableField",

  components: { FormFieldLabel, TableFieldInput },

  props: {
    value: {
      type: [Array, String],
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isTableFieldInputVisible: false,
    };
  },

  methods: {
    showTableFieldInput() {
      this.isTableFieldInputVisible = true;
    },

    getColumnSum(columnName) {
      if (!this.value.length) {
        return 0;
      }

      return this.value.reduce((acc, cur) => acc + cur[columnName], 0);
    },

    handleSave(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#table-field {
  position: relative;
  width: 100%;

  .edit-table {
    position: absolute;
    right: 0;
    top: -8px;
  }

  table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}
</style>
