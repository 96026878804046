<template>
  <div
    id="bot-recommendations"
    v-ripple="'gray'"
    :class="{ 'is-active': isChipsVisible }"
    v-on="$listeners"
  >
    <BaseIcon
      v-tooltip.top="'Suggestions'"
      name="mdi-robot-outline"
      inherit-color
    />

    <q-menu
      auto-close
      anchor="bottom right"
      self="top right"
      :offset="[0, 8]"
      @input="handleInput"
    >
      <div class="recommendation-chips">
        <div class="title-3 q-ma-md">Bot Recommendations</div>

        <BaseScrollbar height="222px" class="q-px-md q-my-md">
          <div class="chips">
            <template v-for="chip in suggestions">
              <div
                v-if="chip"
                :key="chip"
                v-tooltip.top="chip"
                class="chip"
                :class="value == chip ? 'selected' : ''"
                @click="handleClick(chip)"
              >
                {{ chip }}
              </div>
            </template>
          </div>
        </BaseScrollbar>
      </div>
    </q-menu>
  </div>
</template>

<script>
export default {
  name: "BotRecommendations",

  props: {
    value: {
      type: String,
      default: "",
    },

    field: {
      type: Object,
      required: true,
    },

    suggestions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // chips: [
      //   "2022/02/12 18:30",
      //   "dolor sit amet consectetur",
      //   "adipisicing elit",
      //   "2022/04/30",
      //   "Yes",
      //   "12:30 PM",
      //   "963.25",
      //   "false",
      //   "04/26/2022",
      //   "2022/02/24 11:30 PM",
      //   "labore cum",
      //   "98563",
      //   "eos magnam",
      //   "2022-04-26",
      //   "fugit sequi",
      // ],
      isChipsVisible: false,
    };
  },

  methods: {
    handleInput(value) {
      this.isChipsVisible = value;
    },

    handleClick(chip) {
      const booleanOptions = [
        this.field.options.truthyValue,
        this.field.options.falsyValue,
      ];

      let parsedValue = "";

      switch (this.field.dataType) {
        case "SHORT_TEXT":
          parsedValue = String(chip);
          break;
        case "NUMBER":
          parsedValue = Number(chip) || "";
          break;
        case "DATE":
          parsedValue = this.$day.parseDate(chip);
          break;
        case "TIME":
          parsedValue = this.$day.parseTime(chip);
          break;
        case "DATE_TIME":
          parsedValue = this.$day.parseDateTime(chip);
          break;
        case "BOOLEAN":
          parsedValue = booleanOptions.includes(chip) ? chip : "";
          break;
        case "OMR":
          parsedValue = ["true", "false"].includes(chip) ? chip : "";
          break;
        case "SINGLE_SELECT":
          parsedValue = this.field.options.allowToAddNewOptions
            ? String(chip)
            : "";
          break;
      }

      this.$emit("input", parsedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
#bot-recommendations {
  height: 48px;
  width: 48px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--icon-color);

  &:hover {
    cursor: pointer;
    background-color: var(--hover-bg-color);
  }

  &.is-active {
    color: white;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
  }
}

.recommendation-chips {
  width: 362px;

  .chips {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .chip {
      padding: 8px;
      border: 1px solid var(--divider-color);
      border-radius: 4px;
      width: 161px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .chip:hover {
      background: $primary-2;
    }

    .chip.selected {
      background: $secondary-2;
    }
  }
}
</style>
