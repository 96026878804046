<template>
  <div id="repository-dynamic-fields">
    <!-- text -->

    <!-- :label="field.name" -->
    <div class="row items-center">
      <div class="col-auto cursor-pointer">
        <!-- <FormFieldLabel
            v-if="!showEditText"
            :label="field.name"
            :is-mandatory="false"
            class="header"
          /> -->

        <template v-if="!showEditText">
          <div class="row items-center header" @click="showEditText = true">
            <FormFieldLabel
              v-if="!showEditText"
              :label="field.name"
              :is-mandatory="false"
              class=""
            />
          </div>
        </template>

        <ValidationObserver ref="form">
          <ValidationProvider
            name="label"
            :rules="{
              required: true,
              checkDuplicate: { target: labelError },
            }"
          >
            <!-- <TextField
                v-if="showEditText"
                v-model="field.name"
                :error="errors[0]"
                class="q-mb-sm"
                @input="updateLabel"
              /> -->
            <input
              v-if="showEditText"
              v-model="field.name"
              type="text"
              :autofocus="showEditText"
              class="title q-mb-sm"
              @input="updateLabel(field.name)"
              @mouseleave="validateLabel"
            />
            <FormFieldError v-if="error" :error="error" class="q-mb-sm" />
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </div>

    <ValidationProvider :name="field.name">
      <TextField
        v-if="field.dataType === 'SHORT_TEXT'"
        :value="value"
        @input="handleInput"
        @click="controlFocus(value)"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- number -->

    <ValidationProvider v-if="field.dataType === 'NUMBER'" :name="field.name">
      <NumberField
        :value="value"
        @input="handleInput"
        @click="controlFocus(value)"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- DATE -->

    <ValidationProvider
      v-if="field.dataType === 'DATE'"
      v-slot="{ errors }"
      :name="field.name"
    >
      <DateField
        :value="value"
        :error="errors[0]"
        @input="handleInput"
        @click="controlFocus(value)"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- Time -->

    <ValidationProvider
      v-if="field.dataType === 'TIME'"
      v-slot="{ errors }"
      :name="field.name"
    >
      <TimeField
        :value="value"
        :error="errors[0]"
        @input="handleInput"
        @click="controlFocus(value)"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- date time -->

    <ValidationProvider
      v-if="field.dataType === 'DATE_TIME'"
      v-slot="{ errors }"
      :name="field.name"
    >
      <DateTimeField :value="value" :error="errors[0]" @input="handleInput" />
    </ValidationProvider>

    <!-- ... -->

    <!-- select -->
    <ValidationProvider
      v-if="field.dataType === 'SINGLE_SELECT'"
      v-slot="{ errors }"
      :name="field.name"
    >
      <SelectField
        :value="value"
        :error="errors[0]"
        :new-option="true"
        :options="[]"
        page="repository"
        @input="handleInput"
      />
    </ValidationProvider>
    <!-- .... -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import TextField from "@/components/common/form/text-field/TextField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "RepositoryDynamicField",

  components: {
    ValidationProvider,
    TextField,
    FormFieldLabel,
    ValidationObserver,
    FormFieldError,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
    SelectField,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    repositoryId: {
      type: [String, Number],
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },

    labelName: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return { showEditText: false, labelError: "", error: "" };
  },

  computed: {},

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    controlFocus(value) {
      localStorage.setItem("findText", value);
    },

    updateLabel(label) {
      this.error = "";
      this.labelError = "";
      this.error = !label ? "Label should not be empty" : "";
      let labelFound = false;
      this.fields.forEach((item) => {
        if (item.id !== this.field.id) {
          if (item.name.trim() === label) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
        this.error = "Specified label already assigned";
      }
    },

    async validateLabel() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      this.showEditText = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header:hover {
  cursor: auto;
}

.title {
  color: var(--icon-color);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 13px;
  // line-height: 1.25rem;
}
</style>
