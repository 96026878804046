<template>
  <div id="upload-header">
    <div class="header">
      <div>
        <div class="title-1">upload files</div>
        <div class="description">Uploaded files will be moved to index tab</div>
      </div>

      <q-space />

      <BaseCloseButton @click="$emit('close')" />
    </div>

    <div class="action-bar">
      <Tabs :value="value" :tabs="tabs" @input="handleInput" />

      <q-space />

      <PortalTarget name="upload-index-action-bar" slim />

      <BaseActionButton
        icon="eva-question-mark"
        color="secondary"
        @click="showQuickHelp"
      />
    </div>

    <!-- quick help -->

    <QuickHelp v-model="quickHelp" />

    <!-- ... -->
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs.vue";
import { PortalTarget } from "portal-vue";
import QuickHelp from "@/components/common/QuickHelp.vue";

export default {
  name: "Header",

  components: { Tabs, PortalTarget, QuickHelp },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      quickHelp: false,
      tabs: [
        {
          id: this.$nano.id(),
          label: "upload",
          value: "UPLOAD",
        },
        {
          id: this.$nano.id(),
          label: "index",
          value: "INDEX",
        },
      ],
    };
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    handleInput(tab) {
      this.$emit("input", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
#upload-header {
  .header {
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;

    .description {
      @extend .text-xs;
      color: var(--icon-color);
    }
  }

  .action-bar {
    display: flex;
    align-items: center;
    margin: 0px 16px;
  }
}
</style>
