<template>
  <div id="exporting-file-list">
    <FileList
      :has-files="hasFiles"
      :has-selected-files="hasSelectedFiles"
      :number-of-files="files.length"
      :show-auto-load="showAutoLoad"
      class="q-mb-md"
      @getFiles="$emit('getFiles', 'EXPORT')"
    >
      <!-- label -->

      <template #label>
        <span v-if="label" class="label">{{ label }}</span>
        <span v-else>Exporting</span>
      </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions-maximized>
        <template v-if="hasSelectedFiles">
          <BaseActionButton
            v-tooltip:red.top="'Delete'"
            is-flat
            color="red"
            icon="eva-trash-2-outline"
            class="q-mr-md"
            @click.stop="$emit('delete')"
          />
        </template>
        <div v-if="files.length" class="row">
          <div class="col">
            <!-- <div class="label">Select All</div> -->
          </div>

          <BaseIcon
            v-tooltip.top="'select all'"
            :name="
              isSelectAll
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :color="isSelectAll ? 'secondary' : 'grey'"
            class="q-mx-sm cursor-pointer col-auto"
            @click.stop="selectAllFile"
          />
        </div>
      </template>

      <!-- ... -->

      <template v-if="hasFiles" #actions-minimized>
        <Pulse class="q-mr-md" />
      </template>

      <!-- files -->

      <template #default>
        <FileListItem
          v-for="file in files"
          :key="file.id"
          :file="file"
          :is-selected="isFileSelected(file)"
          @selectFile="fileSelect(file)"
        >
          <!-- status -->

          <template #status>
            <BaseIcon name="mdi-circle-small" />
            <div v-if="file.hasError" class="error">Error</div>
            <template v-else>
              <div class="status">Exporting</div>
              <Pulse class="q-ml-md" />
            </template>
          </template>

          <!-- ... -->

          <!-- actions -->

          <template v-if="hasSelectedFiles" #actions-maximized>
            <BaseActionButton
              v-tooltip:red.top="'Delete'"
              is-flat
              color="red"
              icon="eva-trash-2-outline"
              class="q-mr-md"
              @click.stop="$emit('delete')"
            />
          </template>

          <template v-if="file.hasError">
            <BaseActionButton
              v-tooltip.top="'Reexport'"
              is-flat
              icon="eva-refresh"
              class="q-mr-sm"
              @click.stop="$emit('reexport', file)"
            />

            <BaseActionButton
              v-tooltip:red.top="'Error Log'"
              is-flat
              icon="mdi-file-document-outline"
              color="red"
              @click.stop="$emit('log', file)"
            />
          </template>

          <!-- actions -->

          <BaseIcon
            :name="
              isFileSelected(file)
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :is-inactive="!isFileSelected(file)"
            color="secondary"
            class="q-mr-sm"
            @click.stop="$emit('select', file)"
          />

          <BaseActionButton
            is-flat
            no-border
            icon="eva-more-vertical"
            @click.stop=""
          >
            <q-menu
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
              content-class="q-py-sm"
            >
              <ListItem
                v-for="action in actions"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="$emit(action.value, file)"
              />
            </q-menu>
          </BaseActionButton>

          <!-- ... -->

          <!-- ... -->
        </FileListItem>
      </template>

      <!-- ... -->
    </FileList>
  </div>
</template>

<script>
import FileList from "@/views/upload-and-index/components/FileList.vue";
import FileListItem from "@/views/upload-and-index/components/FileListItem.vue";
import ListItem from "@/components/common/ListItem.vue";
import Pulse from "@/components/common/Pulse.vue";

export default {
  name: "ExportingFileList",

  components: { FileList, FileListItem, ListItem, Pulse },

  props: {
    files: {
      type: Array,
      required: true,
    },

    selectedFiles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          id: this.$nano.id(),
          icon: "eva-eye-outline",
          label: "View",
          value: "view",
        },
        {
          id: this.$nano.id(),
          icon: "eva-trash-2-outline",
          label: "Delete",
          value: "delete",
        },
      ],
      isSelectAll: false,
      showAutoLoad: true,
    };
  },

  computed: {
    hasFiles() {
      return !!this.files.length;
    },

    exportingFiles() {
      return this.selectedFiles.filter((file) => file.status === "EXPORT")
        .length;
    },

    hasSelectedFiles() {
      return !!this.exportingFiles;
    },

    label() {
      if (!this.hasSelectedFiles) {
        return "";
      }

      const chunk = this.exportingFiles === 1 ? "file" : "files";
      return `${this.exportingFiles} ${chunk} selected`;
    },
  },

  methods: {
    isFileSelected(file) {
      return !!this.selectedFiles.find((_file) => _file.id === file.id);
    },

    fileSelect(file) {
      this.$emit("selectedFile", file);
    },

    selectAllFile() {
      this.isSelectAll = !this.isSelectAll;
      this.$emit("selectAll", this.isSelectAll, "EXPORT");
    },
  },
};
</script>

<style lang="scss" scoped>
#exporting-file-list {
  .label,
  .status {
    color: var(--secondary);
  }

  .error {
    color: var(--red);
  }
}
</style>
